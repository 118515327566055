<template>
  <div>
    <sm-loader v-if="getLoanData.loading" />

    <div class="border border-solid border-blue-200">
      <datatable
        :data="loans.data"
        :columns="loans.columns"
        :query="searchQuery"
        :loading="loans.loading"
        :on-click="click"
        :selectable="true"
        :exportable="true"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/loans/tangerine/loans`"
        :search-field="searchField"
        :limit="30"
        ref="table"
        @selection-update="selectionUpdate"
      />
    </div>

    <div
      class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg max-w-500px"
    >
      <div class="text-sm text-gray-700 mb-4">
        {{ selection.length }} Loan{{ selection.length !== 1 ? 's' : '' }}
        Selected!
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-4">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <form-group
        type="select"
        :options="lenderOptions"
        left-icon="business-outline"
        name="lender"
        :form="form"
        :loading="lenders.loading"
        v-model="form.data.lender.value"
      >
        Select Lender
      </form-group>
      <form-group
        name="tenure"
        type="number"
        :form="form"
        v-model="form.data.tenure.value"
        :auto-focus="true"
      >
        {{ form.data.tenure.value == 1 ? 'Month' : 'Months' }}
      </form-group>

      <form-group
        name="interest"
        type="number"
        :form="form"
        v-model="form.data.interest.value"
        :auto-focus="true"
      >
        Interest Rate
      </form-group>

      <div>
        <button
          class="btn btn-blue btn-sm mr-3"
          @click.prevent="sendToTangerineLife"
          :disabled="
            form.loading ||
              !selection.length ||
              !form.data.lender.value ||
              !form.data.tenure.value
          "
        >
          <ion-icon
            name="checkmark-done-outline"
            class="text-lg mr-2"
          ></ion-icon>
          <span v-if="form.loading">Marking</span>
          <span v-else>Send to TangerineLife</span>
        </button>
        <button
          class="btn btn-gray btn-sm"
          @click.prevent="clear"
          :disabled="form.loading || !selection.length"
        >
          <ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
          <span>Clear Selection</span>
        </button>
      </div>
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      lenders: this.$options.resource([]),
      loans: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'Loan ID'
          },
          {
            name: 'name',
            th: 'User Name',
            render: loan => `${loan.user?.name} ${loan.user?.last_name || ''}`
          },
          {
            name: 'company',
            th: 'Company',
            render: loan => loan.user.company?.name
          },
          {
            name: 'requested_amount',
            th: 'Amount Requested',
            render: loan =>
              `₦ ${this.$options.filters.currency(loan?.requested_amount)}`
          },
          {
            name: 'loan_amount',
            th: 'Amount to disburse',
            render: loan =>
              `₦ ${this.$options.filters.currency(loan.loan_amount)}`
          },
          {
            name: 'tenure',
            th: 'Tenure',
            render: loan => `${loan?.tenure} Months`
          },
          {
            name: 'interest_rate',
            th: 'Interest Rate'
          },
          {
            name: 'email',
            th: 'Bank Account',
            render: loan => loan.user.profile?.account_no
          },
          {
            name: 'phone',
            th: 'Bank Name',
            render: loan =>
              this.$options.filters.bankName(
                loan.user.profile?.bank_name,
                this.banks
              )
          },
          {
            name: 'salary_day',
            th: 'Salary Day',
            render: loan => loan?.user?.profile?.salary_day || 'N/A'
          },
          {
            name: 'status',
            th: 'Status',
            render: (loan, status) => {
              switch (status) {
                case 'approved':
                  return `<div class="badge badge-green-soft-outline">
											Approved
										</div>`;
                case 'accepted':
                  return `<div class="badge badge-green-soft-outline">
											Accepted
										</div>`;
                case 'disbursed':
                  return `<div class="badge badge-green-soft-outline">
											Disbursed
										</div>`;
                case 'declined':
                  return `<div class="badge badge-red-soft-outline">
											Declined
										</div>`;
                case 'rejected':
                  return `<div class="badge badge-red-soft-outline">
											Rejected
										</div>`;
                case 'paid':
                  return `<div class="badge badge-green-soft-outline">
											Paid
										</div>`;
                case 'pending':
                default:
                  return `<div class="badge badge-orange-soft-outline">
											Pending
										</div>`;
              }
            }
          },
          {
            name: 'offer-letter',
            th: 'Offer Letter',
            render: loan => {
              if (
                loan.status.match(/approved|accepted|disbursed/) &&
                loan?.source !== this.credpalPay
              ) {
                return `
                  <a
                    href="/${this.ENV_VUE_APP_ADMIN_PATH}/offer-letter/${loan.id}"
                    target="_blank"
                    class="btn btn-sm btn-gray-soft"
                  >
                    View Offer Letter
                  </a>
                `;
              }
            }
          }
        ],
        successes: [],
        failures: []
      }),
      form: this.$options.basicForm(['lender', 'tenure', { name: 'interest', value: null, rules: 'nullable' }]),
      getLoanData: this.$options.resource({})
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    lenderOptions() {
      let options = [];
      this.lenders.data?.forEach(lender => {
        if (lender.name == 'TangerineLife') {
          options.push({
            title: lender.name,
            value: lender.id
          });
        }
      });
      return options;
    },
    selectedUser() {
      return this.loans.selected?.user;
    }
  },
  beforeMount() {
    // this.getLoans();
    this.getLenders();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(loan) {
      this.loans.selected = loan;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    selectionUpdate({ selection }) {
      this.selection = selection;
    },
    async getLoans() {
      this.$refs.table.loadAjaxData();
    },
    async getLenders() {
      this.lenders.loading = true;
      await this.sendRequest('admin.lenders.all', {
        success: response => {
          this.lenders.data = response.data.lenders;
        }
      });
      this.lenders.loading = false;
    },
    async sendToTangerineLife() {
      if (!this.validateForm(this.form)) {
        return false;
      }

      this.loans.successes = [];
      this.loans.failures = [];

      this.form.loading = true;
      this.form.error = null;
      await this.sendRequest('admin.loans.personal.send_to_tangarine', {
        data: {
          loans: this.selection.map(item => item.row.id),
          lender: this.form.data.lender.value,
          tenure: this.form.data.tenure.value,
          interest: this.form.data.interest.value
        },
        success: () => {
          this.$success({
            title: 'Loans Sent to Tangerine',
            body: 'You will be sent an email if there was an error'
          });
          this.clear();
          this.getLoans();
        },
        error: error => {
          this.form.error = error;

          if (error?.response?.data?.failures) {
            this.loans.failures = error?.response?.data?.failures;
          }
        }
      });
      this.form.loading = false;
    }
  }
};
</script>
